import uniqid from 'uniqid'
import LaunchIcon from '@mui/icons-material/Launch';
import { skills } from '../../portfolio'
import './Skills.css'

function Skills() {
  if (!skills.length) return null

  return (
    <section className='section skills' id='skills'>
      <h2 className='section__title'>Skills</h2>
      <ul className='skills__list'>
        {skills.map((skill) => (
          <li key={uniqid()} className='skills__list-item btn btn--plain'>
            { (skill.url && skill.url !== '') ? (
              <a href={skill.url}
                 target='_blank'
                 rel='noopener noreferrer'
              >
                {skill.name} <LaunchIcon className='inline-icon' />
              </a>
            ) : skill.name
        }
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Skills
