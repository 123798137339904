import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { useState } from 'react'
import { Dialog } from '@mui/material'
import { about } from '../../portfolio'
import './About.css'

function About() {
  const { name, role, description, resume, social } = about
  const [resumeOpen, setResumeOpen] = useState(false)

  return (
    <div className='about center'>
      {name && (
        <h1>
          {"Hi, I'm "}
          <span className='about__name'>{name}.</span>
        </h1>
      )}

      {role && <h2 className='about__role'>{role}</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {resume && (
          <button
            type='button'
            onClick={() => setResumeOpen(true)}
          >
            <span type='button' className='btn btn--outline'>
              Resume
            </span>
          </button>
        )}
        <Dialog
          open={resumeOpen}
          onClose={() => setResumeOpen(false)}
          maxWidth='lg'
          fullWidth
          PaperProps={{
            style: {
              height: '80vh'
            }
          }}
        >
          <object
            type='application/pdf'
            data='/resume.pdf#toolbar=0'
            height="100%"
          >
            Resume
          </object>
        </Dialog>

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
