const header = {
  homepage: 'https://sorou.tech/',
  title: 'NB',
}

const about = {
  name: 'Nelson',
  role: 'Software Engineer',
  description:
    'I\'m on a mission to build software that empowers people to improve themselves ' +
    'and the world around them. I\'m always ' +
    'looking to work with like-minded people who can help make that vision a reality.\n\n' +
    'If that sounds like you, and you have some experience with design or marketing, ' +
    'feel free to reach out! Let\'s make the world a better place to live in, one ' +
    'app at a time.',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/nelson-batista',
    github: 'https://github.com/batistan',
  },
}

const projects = [
  // projects can be added and removed
  // if there are no projects, Projects section won't show up
  {
    name: 'CHIP-8 Interpreter',
    description:
      'Interpreter for the CHIP-8 programming language, website to host the interpreter and dynamically load ROMs',
    stack: ['Rust', 'React', 'WebAssembly', 'React'],
    sourceCode: 'https://gitlab.com/sorou/chip8',
  },
  {
    name: 'Food Survival Game',
    description:
      'Submission for LibGDX Game Jam December 2019',
    stack: ['Java', 'LibGDX', 'Gradle'],
    sourceCode: 'https://gitlab.com/sorou/zombiegame',
    livePreview: 'https://batistan.itch.io/food-survival',
  }
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  { name: 'Kotlin', url: 'https://kotlinlang.org/' },
  { name: 'Java', url: 'https://www.java.com/' },
  { name: 'Ktor', url: 'https://ktor.io' },
  { name: 'Spring', url: 'https://spring.io/' },
  { name: 'Gradle', url: 'https://gradle.org' },
  { name: 'API Design & Development' },
  { name: 'AWS', url: 'https://aws.amazon.com/' },
  { name: 'TypeScript', url: 'https://www.typescriptlang.org/' },
  { name: 'React', url: 'https://react.dev/' },
  { name: 'SaaS' },
  { name: 'Git', url: 'https://git-scm.com/' },
  { name: 'CI/CD' },
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'contact@sorou.tech',
}

export { header, about, projects, skills, contact }
