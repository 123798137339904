import { createContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const ThemeContext = createContext('light')

function ThemeProvider({ children }) {
  // if user has stored a preferred theme, pull it up here
  const storedTheme = localStorage.getItem('themeName')
  const [themeName, setThemeName] = useState(storedTheme || 'light')

  useEffect(() => {
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    // allow stored theme to override browser default
    if (typeof storedTheme === 'undefined') {
      setThemeName(darkMediaQuery.matches ? 'dark' : 'light')
    }
    darkMediaQuery.addEventListener('change', (e) => {
      setThemeName(e.matches ? 'dark' : 'light')
    });
  }, [])

  const toggleTheme = () => {
    const name = themeName === 'dark' ? 'light' : 'dark'
    localStorage.setItem('themeName', name)
    console.log(`Setting theme to ${name}`)
    setThemeName(name)
  }

  const value = useMemo(
    () => [themeName, toggleTheme],
    [themeName, toggleTheme]
  )

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { ThemeProvider, ThemeContext }
