import './Footer.css'

function Footer() {
  return <footer className='footer'>
    <a
      href='https://github.com/rjshkhr/cleanfolio'
      className='link footer__link'
    >
      Template by Raj Shekhar
    </a>
  </footer>
}

export default Footer
